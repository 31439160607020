import React from "react"
import contactListStyles from "../styles/contactList.module.scss"
import { motion } from "framer-motion"

const ContactList = () => {
  return (
    <div className={contactListStyles.contactContainer}>
      <motion.div
        initial={{ scale: 0.75, opacity: 0, transition: { duration: 0.5 } }}
        animate={{ scale: 1, opacity: 1, transition: { duration: 0.5 } }}
        className={contactListStyles.contact}
      >
        <h1>Send me a message!</h1>
        <form
          target="_blank"
          action="https://formsubmit.co/milos.agathon@gmail.com"
          method="POST"
        >
          <div className={contactListStyles.formElement}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className={contactListStyles.formIcon}
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-4.987-3.744A7.966 7.966 0 0 0 12 20c1.97 0 3.773-.712 5.167-1.892A6.979 6.979 0 0 0 12.16 16a6.981 6.981 0 0 0-5.147 2.256zM5.616 16.82A8.975 8.975 0 0 1 12.16 14a8.972 8.972 0 0 1 6.362 2.634 8 8 0 1 0-12.906.187zM12 13a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
            </svg>
            <input
              type="text"
              name="name"
              className={contactListStyles.formControl}
              placeholder="name"
              required
            />
          </div>
          <div className={contactListStyles.formElement}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className={contactListStyles.formIcon}
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M20 12a8 8 0 1 0-3.562 6.657l1.11 1.664A9.953 9.953 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10v1.5a3.5 3.5 0 0 1-6.396 1.966A5 5 0 1 1 15 8H17v5.5a1.5 1.5 0 0 0 3 0V12zm-8-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6z" />
            </svg>
            <input
              type="email"
              name="email"
              className={contactListStyles.formControl}
              placeholder="email"
              required
            />
          </div>

          <textarea
            placeholder="message"
            className={contactListStyles.formControl}
            name="message"
            rows="5"
            required
          ></textarea>

          <input
            type="hidden"
            name="_next"
            value="https://milospopovic.net/"
          ></input>
          <input
            type="hidden"
            name="_subject"
            value="New message submitted on /contact page"
          ></input>
          <input type="hidden" name="_replyto"></input>
          <div>
            <button
              type="submit"
              className={`${contactListStyles.formControl} ${contactListStyles.contactButton}`}
            >
              Send{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className={contactListStyles.icon}
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z" />
              </svg>
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  )
}

export default ContactList
